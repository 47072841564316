<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/lesson/lesson' }">课程列表</el-breadcrumb-item>
        <el-breadcrumb-item>编辑课程</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="height: 20px"></div>
    <!--内容-->
    <div class="page-content-x">
      <!-- 如果当前课程违规，显示违规内容 -->
      <div v-if="(form.examine_status === 3 || form.examine_status === 2) && lesson_uuid"
        style="width: 400px; border-radius: 8px ;position: sticky;top:-20px;background-color: #fff;z-index: 10;padding: 12px 0;box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);">
        <div style="border-left: 10px solid #F56C6C;padding-left: 24px;">
          <h4 style="color: #fc142f;margin: 0;"> {{ form.examine_status === 3 ? '当前课程已违规下架' : '当前课程审核失败' }} </h4>
          <p style="color: #fc142f;font-size: 14px;margin: 12px 0;">原因：{{ form.examine_reason }}</p>
        </div>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12">
          <h3>{{ `${lesson_uuid ? '编辑' : '添加'}课程` }}</h3>
        </el-col>
      </el-row>
      <div style="height: 20px;"></div>
      <!--详情-->
      <el-row>
        <el-col :span="16">
          <el-form v-loading="loading" size="medium" ref="form" :model="form" :label-width="this.env.label_width">
            <el-form-item v-if="this.Tool.is_empty(this.lesson_uuid)">
              <el-button size="medium" type="primary" @click="template()">选择模板</el-button>
            </el-form-item>
            <el-form-item label="课程名称">
              <el-input v-model="form.lesson_name"></el-input>
            </el-form-item>
            <el-form-item label="运动品类">
              <div class="category-select">
                <el-button type="primary" @click="isMotionCategory = true">选择运动品类</el-button>
              </div>
              <div class="form-tag_colour-div">
                <el-tag :key="item.index" v-for="(item, index) in motion_category_two" class="form-tag_colour-tag"
                  closable @close="motionClose(index)">
                  {{ `${item.pidName ? item.pidName + '-' + item.name : item.name}` }}
                </el-tag>
              </div>
            </el-form-item>
            <el-form-item label="运动效果">
              <div class="category-select">
                <el-button type="primary" @click="isMotioneffect = true">选择运动效果</el-button>
              </div>
              <div class="form-tag_colour-div">
                <el-tag :key="item.index" v-for="(item, index) in target_category_zero" class="form-tag_colour-tag"
                  closable @close="effectClose(index)">
                  <!-- ` item.pidName + '-' + item.name` -->
                  {{ `${item.pidName ? item.pidName + '-' + item.name : item.name}` }}
                </el-tag>
              </div>
            </el-form-item>
            <el-form-item v-if="this.Tool.is_empty(this.lesson_uuid)" label="课程类型">
              <el-radio v-model="form.lesson_type" :label="1">团课</el-radio>
              <el-radio v-model="form.lesson_type" :label="2">训练营</el-radio>
              <el-radio v-model="form.lesson_type" :label="3">私教</el-radio>
            </el-form-item>
            <el-form-item v-if="this.Tool.is_empty(this.lesson_uuid) && form.lesson_type === 1" label="课程子类型">
              <el-radio v-model="form.lesson_type2" :label="1">大团课</el-radio>
              <el-radio v-model="form.lesson_type2" :label="2">小团课</el-radio>
              <el-radio v-model="form.lesson_type2" :label="3">小团私</el-radio>
            </el-form-item>
            <el-form-item v-if="!this.Tool.is_empty(this.lesson_uuid)" label="课程类型">
              <el-tag v-if="form.lesson_type === 1" size="mini">团课</el-tag>
              <el-tag v-if="form.lesson_type === 2" size="mini">训练营</el-tag>
              <el-tag v-if="form.lesson_type === 3" size="mini">私教</el-tag>
              <el-tag v-if="form.lesson_type2 === 1" size="mini" type="success">大团课</el-tag>
              <el-tag v-if="form.lesson_type2 === 2" size="mini" type="success">小团课</el-tag>
              <el-tag v-if="form.lesson_type2 === 3" size="mini" type="success">小团私</el-tag>
            </el-form-item>
            <el-form-item label="课程视频">
              <Qnupload v-model="form.lesson_video" type="video" />
              <el-checkbox v-model="lesson_video_carousel">视频放到轮播图</el-checkbox>
            </el-form-item>
            <el-form-item label="课程封面" prop="cover">
              <Qnupload v-model="form.cover" :isclipper="true" :compress="false" :fixedNumber="[1, 1]" />
              <span class="form-tip">图片尺寸1024*1024 或 宽:高=1:1，用于课程卡片封面展示。</span>
            </el-form-item>
            <el-form-item label="课程照片" prop="photo">
              <Qnupload v-model="form.lesson_photo" :sum="5" :isclipper="true" :compress="false"
                :fixedNumber="[25, 28]" />
              <span class="form-tip">图片尺寸 宽:高≈25:28，用于详情页轮播图展示。</span>
            </el-form-item>

            <el-form-item label="课程价格￥" v-if="form.lesson_type !== 3">
              <el-input-number v-model="form.price_indication" :step="0.01" :min="0" :max="999999"
                step-strictly></el-input-number>
              <!--                        <el-input v-model="form.price_indication"></el-input>-->
            </el-form-item>
            <el-form-item label="首次体验价￥" v-if="form.lesson_type !== 3">
              <el-input-number v-model="form.price_first" :step="0.01" :min="0" :max="999999"
                step-strictly></el-input-number>
              <!--                        <el-input v-model="form.price_first"></el-input>-->
            </el-form-item>

            <!--                    <el-form-item label="课程价格">-->
            <!--                        <el-input v-model="form.price_indication"></el-input>-->
            <!--                    </el-form-item>-->
            <!--                    <el-form-item label="首次体验价">-->
            <!--                        <el-input v-model="form.price_first"></el-input>-->
            <!--                    </el-form-item>-->
            <el-form-item label="课程时长">
              <el-input v-model="form.lesson_duration"></el-input>
            </el-form-item>
            <el-form-item label="课程简介">
              <el-input v-model="form.introduce" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" maxlength="100"
                show-word-limit></el-input>
              <!--                        &lt;!&ndash; 富文本编辑框 &ndash;&gt;-->
              <!--                        <Wangeditor ref="wangeditor_introduce" v-model="form.introduce" idindex="introduce"-->
              <!--                                    seewWidth="50%"/>-->
            </el-form-item>
            <el-form-item label="训练效果">
              <el-input v-model="form.effect" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" maxlength="2000"
                show-word-limit></el-input>
              <!--                        <Wangeditor ref="wangeditor_effect" v-model="form.effect" idindex="effect"-->
              <!--                                    seewWidth="50%"/>-->
            </el-form-item>
            <el-form-item label="适用人群">
              <el-input v-model="form.suit" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" maxlength="2000"
                show-word-limit></el-input>
              <!--                        <Wangeditor ref="wangeditor_suit" v-model="form.suit" idindex="suit"-->
              <!--                                    seewWidth="50%"/>-->
            </el-form-item>
            <el-form-item label="FAQ">
              <el-input v-model="form.faq" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" maxlength="2000"
                show-word-limit></el-input>
              <!--                        <Wangeditor ref="wangeditor_faq" v-model="form.faq" idindex="faq"-->
              <!--                                    seewWidth="50%"/>-->
            </el-form-item>
            <el-form-item label="注意事项">
              <el-input v-model="form.attention" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" maxlength="2000"
                show-word-limit></el-input>
              <!--                        &lt;!&ndash; 富文本编辑框 &ndash;&gt;-->
              <!--                        <Wangeditor ref="wangeditor_attention" v-model="form.attention" idindex="attention"-->
              <!--                                    seewWidth="50%"/>-->
            </el-form-item>
            <el-form-item label="详情">
              <!-- 富文本编辑框 -->
              <Wangeditor ref="wangeditor_details_rich_text" v-model="form.details_rich_text" idindex="details_rich_text"
                seewWidth="50%" />
            </el-form-item>
            <el-form-item>
              <el-button v-if="is_auth('lesson.lesson.issave')" size="medium" type="primary" @click="save">保存
              </el-button>
              <el-button size="medium" @click="isreturn">返回</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

    </div>
    <!--详情-->
    <el-dialog v-loading="loading" title="选择模板" :visible.sync="template_page" width="70%" :close-on-press-escape="false"
      :close-on-click-modal="false">
      <el-row>
        <Template v-on:template_select="template_select" />
      </el-row>
    </el-dialog>
    <MotionCategory v-model="isMotionCategory" :motion_category_two="motion_category_two"
      @selectData="onSelectCategoryData" />
    <Motioneffect v-model="isMotioneffect" :target_category_zero="target_category_zero" @selectData="onMotioneffect" />
  </div>
</template>
<script>
import Wangeditor from "@/components/Wangeditor";
import Qnupload from "@/components/Qnupload";
import Template from "./Template";
import MotionCategory from '@/components/MotionCategory'
import Motioneffect from '@/components/Motioneffect'
export default {
  components: {
    Wangeditor,
    Qnupload,
    Template,
    MotionCategory,
    Motioneffect
  },
  data() {
    return {
      select_tag: "",
      tag_list: [],
      loading: true,
      lesson_uuid: "",
      form: {
        lesson_type: 1,
        lesson_type2: 1,
        lesson_video: "",
        lesson_photo: [],
        lesson_tag: [],
        price_indication: 0,
        price_first: 0,
        cover: "",
      },
      lesson_video_carousel: false, // 视频是否加入轮播图
      template_page: false, // 模板显示隐藏
      isMotionCategory: false,
      motion_tag: '',
      motion_category_two: [],
      isMotioneffect: false,
      target_category_zero: [],
      motion_effect: ''
    };
  },
  // 创建
  created() {
    this.loading = false;
    this.init();
    this.gettag();
  },
  // 安装
  mounted() { },
  methods: {
    // 打开模板
    template() {
      this.template_page = true;
    },
    // 添加运动品类
    onSelectCategoryData(data) {
      this.motion_category_two = data;
      this.motion_tag = this.motion_category_two.length > 1 ? this.motion_category_two[0].name + ' ...' : this.motion_category_two[0].name
    },
    // 删除运动品类
    motionClose(index) {
      this.motion_category_two.splice(index, 1);
      this.motion_tag = this.motion_category_two.length > 1 ? this.motion_category_two[0].name + ' ...' : this.motion_category_two.length === 1 ? this.motion_category_two[0].name : ''
    },
    // 添加运动效果
    onMotioneffect(data) {
      this.target_category_zero = data;
      this.motion_effect = this.target_category_zero.length > 1 ? this.target_category_zero[0].name + ' ...' : this.target_category_zero[0].name
    },
    // 删除运动效果
    effectClose(index) {
      this.target_category_zero.splice(index, 1);
      this.motion_effect = this.target_category_zero.length > 1 ? this.target_category_zero[0].name + ' ...' : this.target_category_zero.length === 1 ? this.target_category_zero[0].name : ''
    },
    // 模板选择
    template_select(data) {
      console.log('data', data)
      this.form = data;
      this.target_category_zero = data.lesson_target_category;
      this.motion_category_two = data.lesson_motion_category;

      this.motion_tag = this.motion_category_two.length > 1 ? this.motion_category_two[0].name + ' ...' : this.motion_category_two[0].name
      this.motion_effect = this.target_category_zero.length > 1 ? this.target_category_zero[0].name + ' ...' : this.target_category_zero[0].name
      this.template_page = false;
    },
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init() {
      let lesson_uuid = this.$route.query.lesson_uuid;
      if (lesson_uuid !== undefined) {
        this.lesson_uuid = lesson_uuid;
        this.getinfo();
      } else {
        this.loading = false;
      }
    },
    // 获取标签
    gettag() {
      let postdata = {
        api_name: "lesson.lesson.gettag",
        token: this.Tool.get_l_cache("token"),
      };
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tag_list = json.data;
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 添加标签
    add_tag() {
      let select_tag = this.select_tag.split("|");
      let tag = {
        tag_name: select_tag[0],
        tag_colour: select_tag[1],
      };
      if (!this.tag_exist(tag.tag_name)) {
        this.form.lesson_tag.push(tag);
      }
      this.select_tag = "";
    },
    // 判断标签是否存在
    tag_exist(tag_name) {
      if (this.form.lesson_tag.length > 0) {
        for (let v of this.form.lesson_tag) {
          if (tag_name == v.tag_name) {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    },
    // 删除标签
    tagClose(tag) {
      this.form.lesson_tag.splice(this.form.lesson_tag.indexOf(tag), 1);
    },
    // 门店详情
    getinfo() {
      let postdata = {
        api_name: "lesson.lesson.getinfo",
        token: this.Tool.get_l_cache("token"),
        lesson_uuid: this.lesson_uuid,
      };
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.form = json.data;
          this.motion_category_two = json.data.lesson_motion_category
          this.target_category_zero = json.data.lesson_target_category
          this.motion_tag = this.motion_category_two.length > 1 ? this.motion_category_two[0].name + ' ...' : this.motion_category_two[0].name
          this.motion_effect = this.target_category_zero.length > 1 ? this.target_category_zero[0].name + ' ...' : this.target_category_zero[0].name
          // this.$refs.wangeditor_introduce.append(json.data.introduce);    // 课程简介
          // this.$refs.wangeditor_effect.append(json.data.effect);          // 训练效果
          // this.$refs.wangeditor_suit.append(json.data.suit);              // 适用人群
          // this.$refs.wangeditor_faq.append(json.data.faq);                // FAQ
          // this.$refs.wangeditor_attention.append(json.data.attention);    // 注意事项
          this.$refs.wangeditor_details_rich_text.append(json.data.details_rich_text); // 详情富文本

          this.lesson_video_carousel =
            json.data.lesson_video_carousel === 1 ? true : false; //视频是否加入轮播图
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    //保存
    save() {
      let motion_category_two = []
      let target_category_zero = []
      this.motion_category_two.forEach(element => {
        motion_category_two.push(element.id)
      });
      this.target_category_zero.forEach(element => {
        target_category_zero.push(element.id)
      });
      let postdata = {
        api_name: "lesson.lesson.issave",
        token: this.Tool.get_l_cache("token"),
        motion_category_two,
        target_category_zero
      };

      Object.assign(postdata, this.form);

      postdata.lesson_video_carousel = this.lesson_video_carousel ? 1 : 0; // 视频是否加入轮播图
      let reg = RegExp(
        /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
      );
      if (motion_category_two.length === 0) {
        this.$message.error('请选择运动品类');
        return
      }
      if (target_category_zero.length === 0) {
        this.$message.error('请选择运动效果');
        return
      }
      if (postdata["lesson_name"].match(reg)) {
        this.$message.error('课程名称不可包含特殊字符');
      } else {
        this.loading = true;
        this.Tool.post_data("merchant", postdata, (json) => {
          this.loading = false;
          if (json.code === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: this.env.message_duration,
              onClose: () => {
                // 判断是否有课程id，如果有返回详情（编辑的情况），没有返回列表（添加的情况）
                this.isreturn(); // 返回到列表
              },
            });
          } else {
            this.Tool.errormes(json);
          }
        });
      }
      //   postdata["lesson_name"] = postdata["lesson_name"].replace(
      //     /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
      //     "替换"
      //   );
    },
    // 返回
    isreturn() {
      this.$router.push({ path: "/lesson/lesson" });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.category-select-child {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 60%;
  cursor: pointer;
}
</style>
