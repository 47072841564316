<template>
    <!--内容-->
    <div class="page-content-x">
        <!--搜索-->
        <div v-show="issearch" class="page-content-search">
            <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="课程名称">
                            <el-input v-model="search.lesson_name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="运动品类">
                            <el-input v-model="search.lesson_motion_category"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="运动效果">
                            <el-input v-model="search.lesson_target_category"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="page-content-search-button">
                    <el-col :span="24">
                        <el-form-item>
                            <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                            </el-button>
                            <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <!--标题-->
        <el-row>
            <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
            <el-col :span="12">
                <div class="top-operation-button">
                    <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                               @click="issearch = !issearch">隐藏搜索
                    </el-button>
                    <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                               @click="issearch = !issearch">显示搜索
                    </el-button>
                </div>
            </el-col>
        </el-row>
        <div style="height: 20px;"></div>
        <!--列表-->
        <el-table
                size="medium"
                border
                v-loading="loading"
                :data="tableData"
                style="width: 100%">
            <el-table-column
                    prop="lesson_name"
                    label="课程名称"
                    width="200">
                <template slot-scope="scope">
                    <span>{{ scope.row.lesson_name }}</span>
                </template>
            </el-table-column>
            <el-table-column
                    prop="lesson_name"
                    label="课程类型"
                    width="200">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.lesson_type===1" size="mini">团课</el-tag>
                    <el-tag v-if="scope.row.lesson_type===2" size="mini">训练营</el-tag>
                    <el-tag v-if="scope.row.lesson_type===3" size="mini">私教</el-tag>
                    <el-tag v-if="scope.row.lesson_type2===1" size="mini" type="success">大团课</el-tag>
                    <el-tag v-if="scope.row.lesson_type2===2" size="mini" type="success">小团课</el-tag>
                    <el-tag v-if="scope.row.lesson_type2===3" size="mini" type="success">小团私</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="lesson_tag"
                    label="运动品类"
                    min-width="200">
                <template slot-scope="scope">
                    <el-tag
                            :key="item.id"
                            v-for="item in scope.row.lesson_motion_category"
                            class="form-tag_colour-tag"
                            effect="dark"
                            size="mini"
                            >
                        {{ item.name }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="lesson_tag"
                    label="运动效果"
                    min-width="200">
                <template slot-scope="scope">
                    <el-tag
                            :key="item.id"
                            v-for="item in scope.row.lesson_target_category"
                            class="form-tag_colour-tag"
                            effect="dark"
                            size="mini"
                            >
                        {{ item.name }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="lesson_duration"
                    label="课程时长"
                    width="180">
                <template slot-scope="scope">
                    <span>{{ scope.row.lesson_duration }}分钟</span>
                </template>
            </el-table-column>
            <el-table-column
                    prop="price_indication"
                    label="指导价格"
                    width="180">
                <template slot-scope="scope">
                    <span>￥{{ scope.row.price_indication }}</span>
                </template>
            </el-table-column>
            <el-table-column
                    prop="state"
                    label="状态"
                    width="100">
                <template slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.state===1">正常</el-tag>
                    <el-tag size="mini" v-if="scope.row.state===2" type="danger">禁用</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="200">
                <template slot-scope="scope">
                    <el-button
                            v-if="is_auth('lesson.lessontemplate.getinfo')"
                            @click="tosee(scope.row.lesson_uuid)"
                            size="mini">选用
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="height: 20px;"></div>
        <!--分页-->
        <el-pagination
                @current-change="getlist"
                :page-size="this.env.pageSize"
                :pager-count="7"
                background
                layout="prev, pager, next, total"
                :current-page.sync="page"
                :total="count">
        </el-pagination>

    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '课程模板',
            loading: true,      // 加载状态
            issearch: false,    // 搜索是否展示
            search: {},         // 搜索内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],// 列表内容
        }
    },
    // 创建
    created() {
        this.init()

    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                lesson_name: '',
                publish_state: '',
                lesson_tag_s: '',
                lesson_motion_category: '',
                lesson_target_category: ''
            }
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "lesson.lessontemplate.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 查看
        tosee(lesson_uuid = '') {
            let postdata = {
                api_name: "lesson.lessontemplate.getinfo",
                token: this.Tool.get_l_cache('token'),
                lesson_uuid: lesson_uuid,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    let data = json.data;
                    delete data.lesson_uuid
                    this.$emit('template_select', data)
                } else {
                    this.Tool.errormes(json)
                }
            })

        },


    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*表格不换行*/
>>> .el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    min-height: 200px;
}

</style>
